import ReactGA from 'react-ga';
import config from '../config';
import { logrocketInit } from '../config/logRocket';

// declare const twq: any;
// declare const lintrk: any;
// declare const fbq: ((...args: any[]) => void) | undefined;
// declare  Window {
//   gtag: any;
//   fbq: any;
// }

const callWindowFN = (fnName: string, ...args: any[]) => {
  if (import.meta.env.DEV) return;
  typeof window !== 'undefined' && (window as any)[fnName] && (window as any)[fnName](...args);
};

export class VendorAnalytics {
  static initAll() {
    VendorAnalytics.GoogleAnalytics();
    VendorAnalytics.LogRocketInit();
  }
  static GoogleAnalytics() {
    if (config.gaTrackingCode) {
      ReactGA.initialize(config.gaTrackingCode);
    }
  }
  static LogRocketInit() {
    if (import.meta.env.DEV) return;
    if (config.logrocketAppId) {
      logrocketInit();
    }
  }

  static onCheckoutInit() {
    callWindowFN('gtag', 'event', 'begin_checkout', {
      send_to: config.gAdsTrackingCode,
    });

    callWindowFN('fbq', 'track', 'InitiateCheckout');
  }

  static onTrialStart() {
    // window.gtag?.('event', 'begin_trial', {
    //   send_to: config.gAdsTrackingCode,
    // });
    // window?.fbq?.('track', 'StartTrial', { value: '0.00', currency: 'USD', predicted_ltv: '0.00' });

    callWindowFN('gtag', 'event', 'begin_trial', {
      send_to: config.gAdsTrackingCode,
    });

    callWindowFN('fbq', 'track', 'StartTrial', { value: '0.00', currency: 'USD', predicted_ltv: '0.00' });
  }

  static GAdsSignupPageConversion() {
    // window?.gtag?.('event', 'conversion', { send_to: config.gAdsTrackingCode });
    // window?.fbq?.('track', 'Lead');

    callWindowFN('gtag', 'event', 'conversion', { send_to: config.gAdsTrackingCode });
  }

  static GAdsComRegistrationConversion() {
    // window.gtag?.('event', 'conversion', {
    //   send_to: config.gAdsTrackingCode,
    // });
    // window?.fbq?.('track', 'CompleteRegistration');

    callWindowFN('gtag', 'event', 'conversion', {
      send_to: config.gAdsTrackingCode,
    });

    callWindowFN('fbq', 'track', 'CompleteRegistration');
  }

  static GAdsPurchaseConversion() {
    // window.gtag?.('event', 'conversion', {
    //   send_to: config.gAdsTrackingCode,
    // });

    callWindowFN('gtag', 'event', 'conversion', {
      send_to: config.gAdsTrackingCode,
    });
  }

  static TriggerSignupPageConversions() {
    VendorAnalytics.GAdsSignupPageConversion();
  }

  static TriggerRegistrationConversions() {
    VendorAnalytics.GAdsComRegistrationConversion();
  }

  static TriggerPurchaseConversions(price: number) {
    VendorAnalytics.GAdsPurchaseConversion();
    // ReactGA.event({
    //   category: 'chargbee',
    //   action: 'upgrade plan',
    // });

    // window?.fbq?.('track', 'Subscribe', { value: price.toString(), currency: 'USD', predicted_ltv: price.toString() });

    callWindowFN('fbq', 'track', 'Subscribe', {
      value: price.toString(),
      currency: 'USD',
      predicted_ltv: price.toString(),
    });
  }
}
